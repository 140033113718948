import { useRouter } from 'next/router';
import React from 'react';
import imgMeta from '../../../public/assets/img/meta.jpg';
import { MetaHelper } from './meta-helper';

export const Meta = ({
  title,
  description,
  image,
}: {
  title: string;
  description?: string;
  image?: string | null;
}): React.JSX.Element => {
  const { asPath } = useRouter();
  return (
    <MetaHelper
      url={new URL('https://svg1921.at' + asPath)}
      siteName="SV Gmundner Milch"
      themeColor="#203c77"
      title={title}
      description={description ?? ''}
      image={image ? process.env.NEXT_PUBLIC_STORAGE + image : imgMeta.src}
      locale="de-AT"
      defaultLocale="de-AT"
      locales={['de-AT']}
    />
  );
};
